.footer {
  z-index: 100;
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 9px 0;
  font-size: 0.8rem;
  font-family: "RobotoMono-Regular";
  text-transform: uppercase;
  border-top: 1px solid;

  .email:hover {
   font-family: "Celebrate-Retro";
   font-size: 1.7rem;
   transition: .3s!important;
}
}

.footer-links {
  float: right;
  margin-right: 5%;
  .footer-links-divider {
    margin: 0 7px;
  }
}

/* MED-SMALL SCREENS */

@media only screen and (max-width: 900px) {
  .footer {
    font-size: 0.75rem;

    .footer-links-divider {
      display: none;
    }

    .email:hover {
      font-family: "Celebrate-Retro";
      font-size: 1.7rem;
   }
  }
}
