/************  HERO / LANDING  *************/

#hero-image {
  position: fixed;
  margin-left: 55vw;
  height: 100vh;
  width: 45vw;
  overflow: hidden;
  animation: fade 4s;
  opacity: 0.969;

  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.969;
    }
  }
}

#title {
  font-family: "Celebrate-Retro";
  font-size: 4vw;
  top: 5vh;
  margin-left: 11vw;
  opacity: 1;
  animation: move 3s;
  line-height: 1;

  @keyframes move {
    from {
      margin-left: 5vw;
      opacity: 0;
    }
    to {
      margin-left: 11vw;
      opacity: 1;
    }
  }
}

/************ SIDE NAV LINKS ***************/

.side-nav {
  .side-nav-link,
  #previous,
  #next {
    text-transform: lowercase;
    font-size: 0.85rem;
    font-family: "RobotoMono-Regular";
    line-height: 1.5;

    li {
      width: 0px;
      z-index: 100;
    }
  }
}

.website {
   font-weight: bold;
  }

.side-nav-link:hover,
.website:hover,
#previous:hover,
#next:hover {
  color: rgb(224, 146, 255) !important;
  transition: .3s!important;
}

#project-number {
   font-size: 1rem;
   font-family: "Celebrate-Retro";
   padding: 0 5px;
}

/***************  SECTIONS  ****************/

section {
  position: relative;
  display: block;
  width: 100%;
  height: 100vh;
}

section .cover img #hero {
  position: absolute;
  display: block;
  min-width: 100%;
  min-height: 100%;
  top: 50%;
  left: 50%;
}

section .content {
  position: absolute;
  display: block;
  z-index: 2;
}

/****************  IMAGES  *****************/

.square {
  width: 45vw;
  float: right;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}

.poster {
  width: 35vw;
  margin-left: 60vw;
  margin-top: 25vh;
  z-index: 4;
}

.poster-carousel {
  margin-top: 10vh;
}

.carousel-caption,
.last-caption,
.caption {
  padding-top: 20px;
  font-family: "RobotoMono-Regular";
  font-size: 0.7rem;
  text-transform: uppercase;
  color: rgba(23, 23, 23, 0.969);
}

/****************  FONTS  *****************/

.intro-position,
.description-position {
  top: 30vh;
  left: 3%;
  position: relative;
}

.site-link {
  font-size: 1.7rem;
}

.process {
  margin-top: 30vh;
}

.overview,
.description {
  float: left;
  width: 35vw;
  margin-left: 15vw;
  font-family: "Vercetti";
  font-size: 0.9rem;
  line-height: 1.5;
  color: #212121;
}

.description {
  margin-top: 10vh;
}

.description-title,
.subtitle {
  font-family: "Vercetti";
  font-weight: bolder;
  font-size: 1.7rem;
  line-height: 1.4;
  padding: 10px 0 15px 0;
}

.subtitle {
  margin-left: 15vw;
  width: 35vw;
  line-height: 1.2;
}

.title-number {
  margin-top: 40%;
  font-size: 0.9rem;
  line-height: 1.5;
  font-family: "RobotoMono-Regular";
  text-transform: uppercase;
}

.project-type {
  font-family: "RobotoMono-Regular";
  font-size: 0.9rem;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 11vw;
  line-height: 1.4;
}

/**************  BREAKPOINTS  ****************/

//  large screens  //

@media only screen and (min-width: 900px) {
  #title {
    font-size: 4vw;
  }

  ul {
    position: fixed;
    display: block;
    margin-left: 25px;
    bottom: 40px;
    z-index: 3;
  }
}

//  medium screens  //

@media only screen and (max-width: 900px) {
  .project {
    padding: 0 5%;

    .content {
      padding-top: 131px !important;
    }

    section {
      height: 135vh;
      padding: 25px 0;
      img {
        padding-top: 20px;
      }
    }

    /* SIDE NAV */

    .side-nav {
      #next-previous {
        position: fixed;
        display: block;
        margin-left: 32vw;
        bottom: 7px;
        z-index: 1000; 
      }

      #next,
      #previous {
         font-size: 0.77rem;
      }

      #project-number {
         font-size: 0.85rem;
      }

      #section-0,
      #section-1,
      #section-2,
      #section-3,
      #section-4 {
        display: none;
      }

      .side-nav-link {
         font-size: 0px;
      }
    }

        /* HERO IMAGES */

    #hero-image {
      position: absolute;
      margin-left: 30%;
      width: 75vw;
      height: 80vh;
      opacity: 0.65;
      margin-top: -14vh;
      animation: none;
    }

   //  #neuro-hero,
   //  #outta-hero {
   //    margin-top: 1vh!important;
   //  }
   #neuro-hero,
   #parra-hero,
    #soona-hero {
      margin-top: -7vh;
    }

    #title {
      margin-left: 0;
      font-size: 12.5vw;
      animation: none;
      margin-top: 2vh;
    }

    .pettes-title {
      margin-top: -7vh!important;
    }

    .yha-title {
      margin-top: -16vh!important;
    }

    

    #resources {
      display: none;
    }

    .intro-position,
    .description-position {
      left: 0;
    }

    .square {
      opacity: 0;
    }

    .project-type,
    .description,
    .subtitle,
    .poster,
    .overview,
    .process {
      margin-left: 0;
      width: 100%;
    }

    .project-type {
      font-size: 1rem;
      padding-top: 5px;
    }

    .subtitle {
      font-size: 1.6rem;
      padding-top: 25px;
    }

    .caption,
    .last-caption {
      color: rgb(19, 19, 19) !important;
      font-size: 0.69rem;
    }

    .last-caption {
      padding-bottom: 150px;
    }
  }
}

@media only screen and (min-width: 900px) {
  #mobile-images {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  #mobile-images {
    display: all;
  }
}
