/*************  LINK HEADERS  **************/


#soona,
#soona-marquee,
#outta,
#outta-marquee {
  font-family: "Celebrate-Retro";
  
}


#neuro,
#neuro-marquee,
#pettes,
#pettes-marquee {
  font-family: "NeueMachina-Light";
}


#parra,
#parra-marquee,
#yha,
#yha-marquee
 {
 font-family: "QTJupiter";
}

.portfolio {
  padding: 11% 5% 25% 5%;

  .portfolio-intro {
    padding: 40px 0;
    font-family: "Vercetti";
    font-size: 0.9rem;
  }

   .portfolio-header,
  .portfolio-subheader {
    font-size: 0.9rem;
    font-family: "RobotoMono-Regular";
    padding: 10px 0 0 0;
  }

}

.work__type {
  font-size: 0.8rem;
  font-family: "RobotoMono-Regular";
  float: right;
  text-transform: uppercase;
  padding-left: 50px;
  z-index: 0;
  display: flex;
}

.menu__item {
  position: relative;
}

#portfolio-line {
   border-top: 1px solid;
   opacity: 0.45;
}

/**************  ANIMATION  **************/

.menu__item {
  position: relative;
  cursor: pointer;
}

.menu__item-link {
  display: inline-block;
  cursor: pointer;
  position: relative;
  -webkit-transition: opacity 0s;
  transition: opacity 0s;
}

.menu__item-link::before {
  all: initial;
  counter-increment: menu;
  position: absolute;
//   bottom: 60%;
  left: 0;
  pointer-events: none;
}

.menu__item-link:hover {
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  opacity: 0;
}

.menu__item-img {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  border-radius: 10px;
  width: 35vw;
  opacity: 0;
  left: 100%;
  top: 50%;
  -webkit-transform: translate3d(calc(-100% - 6vw), -30%, 0)
    translate3d(0, 20px, 0);
  transform: translate3d(calc(-100% - 6vw), -30%, 0) translate3d(0, 20px, 0);
}

.menu__item-link:hover + .menu__item-img {
  opacity: 1;
  -webkit-transform: translate3d(calc(-100% - 6vw), -30%, 0)
    rotate3d(0, 0, 1, 4deg);
  transform: translate3d(calc(-100% - 6vw), -30%, 0) rotate3d(0, 0, 1, 4deg);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.menu {
  --offset: 1.25vw;
  --move-initial: calc(0% + var(--offset));
  --move-final: calc(-25% + var(--offset));
}

/*  MARQUEE  */ 

.marquee {
  z-index: 2000;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  color: #fff;
  pointer-events: none;
  mix-blend-mode: difference;
}

.marquee__inner {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-animation: marquee 5s linear infinite;
  animation: marquee 5s linear infinite;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}

.menu__item-link:hover ~ .marquee .marquee__inner {
  -webkit-animation-play-state: running;
  animation-play-state: running;
  opacity: 1;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.menu__item-link,
.marquee span {
  white-space: nowrap;
  font-size: 2.25rem;
  padding: 2vw 0.2vw;
}

.marquee span {
   font-style: italic;
   color: rgb(224, 146, 255);
}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate3d(var(--move-initial), 0, 0);
    transform: translate3d(var(--move-initial), 0, 0);
  }
  100% {
    -webkit-transform: translate3d(var(--move-final), 0, 0);
    transform: translate3d(var(--move-final), 0, 0);
  }
}

@keyframes marquee {
  0% {
    -webkit-transform: translate3d(var(--move-initial), 0, 0);
    transform: translate3d(var(--move-initial), 0, 0);
  }
  100% {
    -webkit-transform: translate3d(var(--move-final), 0, 0);
    transform: translate3d(var(--move-final), 0, 0);
  }
}

/**************  BREAKPOINTS  ****************/

@media only screen and (max-width: 900px) {
  .portfolio-header {
    font-size: 2rem;
  }

  .work__type {
    float: none;
    font-size: 0.69rem;
    line-height: 1.5;
    padding-left: 0;
  }

  .menu__item-link,
  .marquee span {
    white-space: nowrap;
    font-size: 1.6rem;
    padding: 3.2vw 1vw;
  }
}

@media only screen and (max-width: 500px) {
   .portfolio {
      padding-top: 100px;
      padding-bottom: 200px;
   }

  .menu__item-img {
    width: 60vw;
  }
}
