.header {
  z-index: 100;
  position: fixed;
  width: 100vw;
  padding: 9px 0;
  font-size: 0.8rem;
  font-family: "RobotoMono-Regular";
  text-transform: uppercase;
  border-bottom: 1px solid;
  

  .portfolio-nav-link:hover,
  .header-logo:hover {
    font-family: "Celebrate-Retro";
    font-size: 1.9rem;
    transition: .5s!important;
  
  }

  .header-links {
    margin-left: 5%;
    .header-links-divider {
      padding: 0 7px 0 57px;
    }
  }
}

/* MED-SMALL SCREENS */

@media only screen and (max-width: 900px) {
  .header {
    font-size: 0.75rem;

    .portfolio-nav-link:hover,
    .header-logo:hover {
      font-family: "Celebrate-Retro";
      font-size: 1.7rem;
    }
  }
}
