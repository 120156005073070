*, *::after, *::before {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

:root {
   --scrollbar-track: transparent;
   --scrollbar-thumb: rgb(224, 146, 255);
   --scrollbar-width: 1px;
   --scrollbar-thumb-radius: 5px;
}

::-webkit-animation {
   // transform: translate3d(0,0,0);
   -webkit-transform-style: preserve-3d;
   -webkit-backface-visibility: hidden;
}

/* custom scroll (CHROMIUM) */

::-webkit-scrollbar {
   width: var(--scrollbar-width);
}

::-webkit-scrollbar-thumb {
   background-color: var(--scrollbar-thumb);
   border-radius: var(--scrollbar-thumb-radius);
}

::-webkit-scrollbar-button {
   display: none;
}

/* custom scroll (FIREFOX) */ 

* {
   scrollbar-width: none;
}

p {
   line-height: 1.8em;
}


/*************  ACCESSIBILITY  **************/

/* screen reader */

.sr-only {
   position: absolute;
   width: 1px;
   height: 1px;
   padding: 0;
   margin: -1px;
   overflow: hidden;
   clip: rect(0, 0, 0, 0);
   white-space: nowrap; /* added line */
   border: 0;
}

/* remove animations if on */

@media (prefers-reduced-motion: reduce) {
   *,
   *::before,
   *::after {
       animation-duration: 0.01ms !important;
       animation-iteration-count: 1 !important;
       transition-duration: 0.01ms !important;
       scroll-behavior: auto !important;
   }
}

/*****************  FONTS  ******************/

@font-face {
   font-family: "Vercetti";
   src: local("Vercetti"), 
   url("./Fonts/Vercetti-Regular.otf");
}

@font-face {
   font-family: "Celebrate-Retro";
   src: local("Celebrate-Retro"),
   url("./Fonts/Celebrate-Retro.otf");
}

@font-face {
   font-family: "RobotoMono-Regular";
   src: local("RobotoMono-Regular"),
   url("./Fonts/RobotoMono-Regular.ttf");
}

@font-face {
   font-family: "QTJupiter";
   src: local("QTJupiter"),
   url("./Fonts/QTJupiter.otf");
}

@font-face {
   font-family: "NeueMachina-Light";
   src: local("NeueMachina-Light"),
   url("./Fonts/NeueMachina-Light.otf");
}



.App {
   margin: 0;
   z-index: 0;
   display: flex;
   flex-direction: column;
   word-wrap: break-word;
   scroll-behavior: smooth;
   box-sizing: border-box;
   height: 100vh;
   width: 100%;
   overflow-x: hidden;
}

/*************  CUSTOM STYLING  **************/

/* layout */

.container {
   margin-left: auto;
   margin-right: auto;
}

.grid {
   display: flex grid;
   gap: 1.5rem;
}

/* images */

img,
picture {
    max-width: 100%;
    display: block;
}

/* links / lists */

a,
a:hover,
a:visited {
    text-decoration: none!important;
    cursor: pointer;
    color: rgba(21, 21, 21, 0.969)!important;
    position: relative;
}

ul, 
li {
   list-style: none;
}

/* background */

// .noise::before {
//    position: fixed;
//    top: 0;
//    left: 0;
//    width: 100vw;
//    height: 100vh;
//    content: "";
//    z-index: -1;
//    background-size: cover!important;
//    background: url(https://media3.giphy.com/media/l0HlB4tK4SDFUkOQw/giphy.gif?cid=790b7611815c9692cad9006532dbcf25147990631200c5d8&rid=giphy.gif&ct=g);
//      // background: url(https://media1.giphy.com/media/oy7wGVaohJYe4/giphy.gif?cid=ecf05e47w9zfo9w5e69oarasbvyxg47ic22xvvyfl250qfaq&rid=giphy.gif&ct=g); 
// }