/****************   HOME   *****************/

.main-container {
  height: 100vh;
  margin-top: 20px;
  padding: 2% 5%;
  display: flex;
  align-items: flex-end;
  position: relative;
  
  h1 {
    z-index: 0;
    font-size: 9.2rem;
    line-height: 1em;
    font-weight: bold;
    margin-bottom: 15vh;
    font-family: "Celebrate-Retro";
    word-wrap: break-word;
    text-transform: uppercase;
  }
}

/*************  BREAK POINTS  **************/

@media screen and (max-width: 1050px) {
  h1 {
    font-size: 8rem !important;
  }
}

@media screen and (max-width: 800px) {
  h1 {
    font-size: 6rem !important;
  }
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 4rem !important;
    margin-bottom: 25vh;
  }
}

@media screen and (max-width: 400px) {
  h1 {
    font-size: 2.969rem !important;
    margin-bottom: 40vh!important;
  }}
