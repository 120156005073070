.carousel {
  #carousel-item {
    object-fit: contain;
    max-height: 450px;
  }

  .carousel-caption {
    font-family: "RobotoMono-Regular";
    font-size: 0.8rem;
    text-transform: uppercase;
      bottom: -35px!important;
      mix-blend-mode: difference;
  }
}

/**************  BREAKPOINTS  ****************/

@media only screen and (max-width: 500px) {
  .carousel {
    display: none;
  }
}
